import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { VersionsMemo } from '../versions-view';

export const Footer = () => {
	const { t } = useTranslation();
	return (
		<footer className="footer">
			<div className="footer-info-container">
				<Typography>{t('footer-user.title')}</Typography>
			</div>
			<VersionsMemo />
		</footer>
	);
};
