import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useStores } from 'common-frontend/components/store-provider';
import { Button, Typography, IconButton } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation, Trans } from 'react-i18next';
import HelpIcon from '@mui/icons-material/Help';
import { AcceptPopup } from 'common-frontend/components/accept-popup';
import { URLS } from 'common-frontend/constants/env';
import { ALLOW_CERTIFICATES, APPLY_COURSE, GET_PDFS, GET_UNI_STATE } from '../../services/api-calls';
import { REMOVE_RELATIONSHIP } from 'common-frontend/services/api-calls';
import { useNavigate } from 'react-router-dom';

export const AdmissionPage = observer(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { PopupStore, AdmissionStore, UserStore, AuthStore, GuidesStore, DocumentsStore } = useStores();
	const { closePopup, openPopup } = PopupStore;
	const { admissionState } = AdmissionStore;
	const { setAllRightMessageType } = GuidesStore;

	const { user } = UserStore;
	const { authProfile } = AuthStore;

	const [pdfs, setPdfs] = useState(null);
	const [finalPopupProps, setFinalPopupProps] = useState({
		id: '',
		isOpen: false,
		title: '',
		confirmText: '',
		onClose: null,
		action: null,
	});

	const intervalRef = useRef(0);

	const allowCert = useCallback(() => {
		openPopup();
		ALLOW_CERTIFICATES().finally(() => closePopup());
	}, [closePopup, openPopup]);

	const getPdfs = useCallback(() => {
		openPopup();
		GET_PDFS(setPdfs).finally(() => {
			setAllRightMessageType('pdf-ready-to-apply');
			closePopup();
		});
	}, [closePopup, openPopup, setAllRightMessageType]);

	const applyForm = useCallback(() => {
		openPopup();
		APPLY_COURSE().finally(() => closePopup());
	}, [closePopup, openPopup]);

	const getUniState = useCallback(() => {
		clearInterval(intervalRef.current);
		openPopup();
		GET_UNI_STATE().finally(() => {
			closePopup();
			console.info('First check admission state done');
			intervalRef.current = setInterval(() => {
				GET_UNI_STATE();
				console.info('Check admission state');
			}, 15 * 1000);
		});
	}, [closePopup, openPopup]);

	useEffect(() => {
		// Check if user was accepted to university
		if (!sessionStorage.getItem('restart-popup-was-opened') && admissionState?.isUniversityCourseConfirmed) {
			setTimeout(() => {
				clearInterval(intervalRef.current);
				console.info('Check admission state loop finished');
				sessionStorage.setItem('restart-popup-was-opened', 'true');
				setFinalPopupProps({
					id: 'final-scenario-popup',
					isOpen: true,
					title: t('admission.final-scenario-popup.title'),
					confirmText: t('admission.final-scenario-popup.confirmText'),
					action: () => {
						openPopup();
						setFinalPopupProps({ ...finalPopupProps, isOpen: false });
						REMOVE_RELATIONSHIP().finally(() => {
							AdmissionStore.reset();
							DocumentsStore.reset();
							closePopup();
							navigate('/');
						});
					},
					onClose: () => {
						setFinalPopupProps({
							id: 'restart-scenario-popup',
							isOpen: true,
							title: t('admission.restart-scenario-popup.title'),
							confirmText: t('admission.restart-scenario-popup.confirmText'),
							action: () => {
								setFinalPopupProps({ ...finalPopupProps, isOpen: false });
								window.open(URLS.betaTest, '_blank');
							},
							onClose: () => {
								setFinalPopupProps({ ...finalPopupProps, isOpen: false });
							},
						});
					},
				});
			}, 0);
		}
	}, [admissionState, AdmissionStore, DocumentsStore, closePopup, finalPopupProps, openPopup, t, navigate]);

	useEffect(() => {
		// post logout
		if (!user || !authProfile || !sessionStorage.getItem('access_token-university')) {
			clearInterval(intervalRef.current);
			console.info('Check admission state loop finished');
			setPdfs(null);
			AdmissionStore.reset();
		}
	}, [user, authProfile, AdmissionStore]);

	useEffect(() => {
		console.info('Start checking admission state');
		getUniState();

		return () => {
			clearInterval(intervalRef.current);
			console.info('Check admission state loop finished');
			closePopup();
		};
	}, [closePopup, getUniState]);

	return (
		<>
			<main className="main-admission">
				<section className="accordion active">
					<Typography variant="h4">{t('admission.title')}</Typography>
					<Typography className="mb-16 mt-16">
						<Trans i18nKey="admission.description" />
					</Typography>
					{user ? (
						<div className="buttons">
							{admissionState?.isSchoolCertificateReady === false && (
								<>
									<Button
										variant="contained"
										id="allow-cert"
										disabled={admissionState?.isSchoolCertificateRequested}
										onClick={allowCert}
									>
										{t('admission.allow-button')}
									</Button>
									{admissionState?.isSchoolCertificateRequested && (
										<IconButton
											className="help-button"
											onClick={() => {
												setAllRightMessageType('admission');
											}}
										>
											<HelpIcon />
										</IconButton>
									)}
								</>
							)}
							{admissionState?.isSchoolCertificateReady === true && (
								<Button variant="contained" id="get-pdf" onClick={getPdfs}>
									{t('admission.get-pdf-button')}
								</Button>
							)}
							{admissionState?.isSchoolCertificateRequested &&
								admissionState?.isSchoolCertificateReady &&
								admissionState?.isUniversityCourseConfirmed === false && (
									<Button variant="contained" id="apply-cert" onClick={applyForm}>
										{t('admission.apply-button')}
									</Button>
								)}
							{pdfs && <MemoPDF pdf={pdfs} user={user} />}
						</div>
					) : (
						<Typography color="error" className="mt-32">
							{t('admission.error-onboarding')}
						</Typography>
					)}
				</section>
			</main>
			<MemoPopup finalPopupProps={finalPopupProps} />
		</>
	);
});

const MemoPDF = React.memo(function MemoPDF({ pdf, user }) {
	if (!user) return null;
	const url = URL.createObjectURL(pdf);
	return (
		<a href={url} id="download-pdf" download="Certificate.pdf">
			Certificate.pdf
		</a>
	);
});

const MemoPopup = React.memo(function MemoPopup({ finalPopupProps }) {
	const { id, isOpen, action, onClose, title, confirmText } = finalPopupProps;
	return <AcceptPopup id={id} isOpen={isOpen} action={action} onClose={onClose} title={title} confirmText={confirmText} />;
});
