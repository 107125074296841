import { getAsync, postAsync } from 'common-frontend/utils/fetch-helper';
import { STORES } from './stores';

export const GET_UNI_STATE = async () => {
	const {
		AdmissionStore: { setAdmissionState },
	} = STORES;
	const response = await getAsync('/school-certificate-readiness');

	if (response.status === 200) {
		const data = await response.json();
		setAdmissionState(data);
	}
};

export const ALLOW_CERTIFICATES = async () => {
	const {
		AdmissionStore: { setAdmissionState },
	} = STORES;
	const response = await getAsync('/allow-school-certificate');

	if (response.status === 200) {
		const data = await response.json();
		setAdmissionState(data);
	}
};

export const GET_PDFS = async (setPdfs) => {
	const response = await getAsync('/download-certificate');

	if (response.status === 200) {
		const buffer = await response.arrayBuffer();
		const blob = new Blob([buffer], { type: 'application/pdf' });
		setPdfs(blob);
	}
};

export const APPLY_COURSE = async () => {
	const {
		AdmissionStore: { setAdmissionState },
	} = STORES;
	const response = await getAsync('/university-course-confirmation');

	if (response.status === 200) {
		const data = await response.json();
		setAdmissionState(data);
	}
};

export const GET_DOCUMENTS = async (type, page, size) => {
	const {
		DocumentsStore: { setDocuments, setPagesCount },
	} = STORES;
	const response = await getAsync(`/documents?page=${page - 1}&size=${size}&sort=createdAt,DESC&type=${type}`);

	if (response.status === 200) {
		const data = await response.json();

		setDocuments(type, data.content);
		setPagesCount(type, data.totalPages);
	}
};

export const SEND_DOCUMENT = async (type, uuid) => {
	const {
		DocumentsStore: { setDocument },
	} = STORES;
	const response = await postAsync('/send-file', { uuid });

	if (response.status === 200) {
		setDocument(type, await response.json());
	}
};

export const SIGN_DOCUMENT = async (type, uuid) => {
	const {
		DocumentsStore: { setDocument },
	} = STORES;
	const response = await postAsync('/sign-file', { uuid });

	if (response.status === 200) {
		setDocument(type, await response.json());
	}
};

export const VERIFY_DOCUMENT = async (type, uuid) => {
	const {
		DocumentsStore: { setDocument },
	} = STORES;
	const response = await postAsync('/verify-file', { uuid });

	if (response.status === 200) {
		setDocument(type, await response.json());
	}
};

export const REVOKE_DOCUMENT = async (type, uuid) => {
	const {
		DocumentsStore: { setDocument },
	} = STORES;
	const response = await postAsync('/revoke-file', { uuid });

	if (response.status === 200) {
		setDocument(type, await response.json());
	}
};
