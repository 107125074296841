import React, { useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { IMAGES } from '../../constants/images';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { PATHS } from '../../constants/paths';
import { observer } from 'mobx-react';
import { useStores } from 'common-frontend/components/store-provider';
import { GET_UNI_STATE } from '../../services/api-calls';

export const HomePage = observer(() => {
	const { t } = useTranslation();
	const { AuthStore, UserStore, AdmissionStore } = useStores();
	const { authProfile } = AuthStore;
	const { user } = UserStore;
	const { admissionState } = AdmissionStore;
	const disableApply = !user || admissionState?.isSchoolCertificateRequested;

	useEffect(() => {
		if (authProfile && user) {
			GET_UNI_STATE();
		}
	}, [authProfile, user]);

	return (
		<>
			<div className="banner-container">
				<img className="banner-image" src={IMAGES.header} alt="img" />
				<div className="banner-background" />
				<div id="overview-banner" className="overview-banner">
					<Link
						onClick={() => {
							window.scrollTo({
								top: document.body.scrollHeight,
								behavior: 'smooth',
							});
						}}
					>
						<Typography variant="h3">{t('home.main.link')}</Typography>
					</Link>
				</div>
			</div>
			<div className="description-container">
				<Grid container spacing={4}>
					<Grid item lg={12} md={12}>
						<Typography variant="h1" className="mt-48">
							{t('home.main.title')}
						</Typography>
						<Typography className="mb-16 mt-16">
							<Trans i18nKey="home.main.description" />
						</Typography>
					</Grid>
				</Grid>
			</div>
			<div className="banner-container">
				<img className="banner-image--stretch" src={IMAGES.informatic} alt="img" />
				<div className="banner-background" />
			</div>
			<div className="description-container">
				<Grid container spacing={4}>
					<Grid item lg={12} md={12}>
						{/* <img className="studies-banner" src={ IMAGES.informatic } alt="img" /> */}
						<Typography variant="h1" className="mb-48 mt-32">
							{t('studies.title')}
						</Typography>
						<div className="studies-table">
							<Typography variant="h2">{t('studies.subtitle')}</Typography>
							<div className="studies-table__column">
								<Typography variant="h4">{t('studies.faculty')}</Typography>
								<Typography className="mb-16">{t('studies.faculty-value')}</Typography>

								<Typography variant="h4">{t('studies.diploma')}</Typography>
								<Typography className="mb-16">{t('studies.diploma-value')}</Typography>

								<Typography variant="h4">{t('studies.admission-restriction')}</Typography>
								<Typography className="mb-16">{t('studies.admission-restriction-value')}</Typography>
							</div>
							<div className="studies-table__column">
								<Typography variant="h4">{t('studies.normal-period')}</Typography>
								<Typography className="mb-16">{t('studies.normal-period-value')}</Typography>

								<Typography variant="h4">{t('studies.start-study')}</Typography>
								<Typography className="mb-16">{t('studies.start-study-value')}</Typography>

								<Link id="apply-now-link" to={PATHS.admission} className={disableApply ? 'disabled' : ''}>
									<Button
										variant="contained"
										size="large"
										disabled={disableApply}
										color={sessionStorage.getItem('apply-now-clicked') ? 'primary' : 'secondary'}
										onClick={() => sessionStorage.setItem('apply-now-clicked', 'true')}
									>
										{t('studies.button')}
									</Button>
								</Link>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		</>
	);
});
