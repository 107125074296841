import React from 'react';
import { Typography } from '@mui/material';
import { versionsFormater } from 'common-frontend/utils/formatters';

export const VersionsMemo = React.memo(function VersionsMemo() {
	return (
		<div className="versions-container">
			<Typography variant="caption">{versionsFormater()}</Typography>
		</div>
	);
});
