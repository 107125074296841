import React, { useCallback } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { IMAGES } from '../../../constants/images';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStores } from 'common-frontend/components/store-provider';
import { SYNC_LANGUAGE } from 'common-frontend/services/api-calls';
import { PATHS } from '../../../constants/paths';

export const Header = observer(() => {
	const { t, i18n } = useTranslation();
	const { UserStore } = useStores();
	const { user } = UserStore;

	const switchLanguage = useCallback(
		(lang) => {
			i18n.changeLanguage(lang); // en || de
			user && SYNC_LANGUAGE();
		},
		[user, i18n],
	);

	return (
		<>
			<div className="logo-container">
				<img className="logo" src={IMAGES.logo} alt="logo" />
			</div>
			<div className="nav-container">
				<nav className="nav">
					<NavLink id="home-link" to={PATHS.home}>
						{t('nav.home')}
					</NavLink>
					{user && (
						<>
							<NavLink id="admission-link" to={PATHS.admission}>
								{t('nav.admission')}
							</NavLink>
							<NavLink id="documents-link" to={PATHS.documents}>
								{t('nav.documents')}
							</NavLink>
							<NavLink id="messages-link" to={PATHS.messages}>
								{t('nav.messages')}
							</NavLink>
						</>
					)}
					<div className="language-switch-container">
						<Link
							className="language-switch"
							translate="no"
							onClick={() => {
								switchLanguage('de');
							}}
						>
							DE
						</Link>
						<Link
							className="language-switch"
							translate="no"
							onClick={() => {
								switchLanguage('en');
							}}
						>
							EN
						</Link>
					</div>
					<NavLink to={PATHS.user} id="user-link" className={!user ? 'disabled' : ''}>
						{t('nav.user')}
					</NavLink>
				</nav>
			</div>
		</>
	);
});
