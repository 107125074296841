import React from 'react';
import { useTranslation } from 'react-i18next';
import { InboundDocumentsTable, OutboundDocumentsTable } from '../components/tables';
import { Box, Typography } from '@mui/material';

export const DocumentsPage = () => {
	const { t } = useTranslation();

	return (
		<main className="main-documents">
			<div className="documents-container">
				<Box mb={10}>
					<InboundDocumentsTable />
				</Box>
				<Box>
					<Typography mb={6}>{t('files.outbound-help-text')}</Typography>
					<OutboundDocumentsTable />
				</Box>
			</div>
		</main>
	);
};
