import { Stack, Typography, Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SendMessageForm } from 'common-frontend/components/forms/send-message-form';
import { MessagesHistory } from 'common-frontend/components/messages-history';

export const MessagesPage = () => {
	const { t } = useTranslation();

	return (
		<main className="main-messages">
			<div className="messages-inner-container">
				<Stack spacing={6}>
					<div>
						<Typography variant="h2" className="mt-32">
							{t('broadcast.messages.content.title')}
						</Typography>
						<Typography className="mt-16">
							<b>{t('broadcast.messages.content.subtitle')}</b>
						</Typography>
					</div>
					<Stack direction="row" spacing={3}>
						<Box width="25%">
							<MessagesHistory />
						</Box>
						<Box width="75%">
							<SendMessageForm />
						</Box>
					</Stack>
				</Stack>
			</div>
		</main>
	);
};
