import { useState, useEffect, useCallback } from 'react';
import { GET_DOCUMENTS, SEND_DOCUMENT, REVOKE_DOCUMENT, SIGN_DOCUMENT, VERIFY_DOCUMENT } from '../services/api-calls';
import { DOWNLOAD_DOCUMENT } from 'common-frontend/services/api-calls';
import { useStores } from 'common-frontend/components/store-provider';
import { useLoading } from 'common-frontend/utils/hooks';

export const PAGE_SIZE = 10;

export function useDocuments(type) {
	const { AuthStore, UserStore, GuidesStore, DocumentsStore } = useStores();
	const { authProfile } = AuthStore;
	const { user } = UserStore;
	const { guideStepUpdate } = GuidesStore;
	const {
		data: {
			[type]: { documents, pagesCount, currentPage },
		},
		setCurrentPage,
	} = DocumentsStore;

	const [loading, setLoading] = useState(false);

	const getDocuments = useCallback(
		(page) => {
			setLoading(true);
			GET_DOCUMENTS(type, page || currentPage, PAGE_SIZE).finally(() => {
				setLoading(false);
			});
		},
		[type, currentPage],
	);

	useEffect(() => {
		if (authProfile && user && !documents) {
			getDocuments();
		}
	}, [authProfile, user, documents, getDocuments]);

	const handleDownload = useCallback(
		(document) => {
			DOWNLOAD_DOCUMENT(document).finally(() => {
				sessionStorage.setItem('document_downloaded', 'true');
				guideStepUpdate();
			});
		},
		[guideStepUpdate],
	);

	const handlePageChange = useCallback(
		(e, newPage) => {
			setCurrentPage(type, newPage);
			getDocuments(newPage);
		},
		[getDocuments, setCurrentPage, type],
	);

	return {
		documents: documents || [],
		currentPage,
		pagesCount,
		loading,
		handleDownload,
		handlePageChange,
		refetch: () => getDocuments(),
	};
}

export function useMutateDocument(type, uuid) {
	const [sendDocument, sending] = useLoading(() => SEND_DOCUMENT(type, uuid));
	const [signDocument, signing] = useLoading(() => SIGN_DOCUMENT(type, uuid));
	const [verifyDocument, verifying] = useLoading(() => VERIFY_DOCUMENT(type, uuid));
	const [revokeDocument, revoking] = useLoading(() => REVOKE_DOCUMENT(type, uuid));

	return {
		sendDocument,
		sending,
		signDocument,
		signing,
		verifyDocument,
		verifying,
		revokeDocument,
		revoking,
	};
}
