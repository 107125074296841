import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useStores } from 'common-frontend/components/store-provider';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { PATHS } from '../../constants/paths';
import { AllRightMessagePopup, onCleanNodes, onPulse, onTooltip } from 'common-frontend/components/guides';

export const Guides = observer(() => {
	const { t, i18n } = useTranslation();
	const { pathname } = useLocation();
	const { AuthStore, QrStore, UserStore, AdmissionStore, DocumentsStore, GuidesStore } = useStores();
	const { authProfile } = AuthStore;
	const { qrIsOpen } = QrStore;
	const { user, userStatusCode } = UserStore;
	const { admissionState } = AdmissionStore;
	const { allDocuments: documents } = DocumentsStore;
	const { allRightMessageType, setAllRightMessageType, isGuideStepUpdate } = GuidesStore; // allRightMessageType === 'logined' || 'onboarded' || 'admission' || 'pdf-shared'

	const debounceRef = useRef(0);
	const currStepRef = useRef('');

	const [allRightPopupIsOpen, setAllRightPopupIsOpen] = useState(false);

	const stepRunners = useMemo(
		() => ({
			beforeAuth() {
				onPulse('auth-link');
				onTooltip('auth-link', t('tooltips.login-tooltip'));

				if (sessionStorage.getItem('max_done_guide_step') === 'beforeAuth') {
					setAllRightMessageType('logined');
				}
			},
			afterAuth() {
				onCleanNodes(['auth-link']);
				setAllRightPopupIsOpen(true);
			},
			userNotOnboarded() {
				onCleanNodes(['auth-link']);
				onPulse('qr-button');
				onTooltip('qr-button', t('tooltips.qr-button-tooltip'));
			},
			userOnboardingPopup() {
				onCleanNodes(['qr-button']);
				onTooltip('qr-tooltip-anchor', t('tooltips.qr-code-tooltip'));
				setAllRightMessageType('onboarded');
			},
			userWaitingOnboarding() {
				onCleanNodes(['auth-link', 'qr-button']);
				setAllRightMessageType('onboarded');
			},
			userOnboarded() {
				setAllRightPopupIsOpen(true);
			},
			userPage() {
				onPulse('back-button');
			},
			documentsPage() {
				onCleanNodes(['documents-link']);
			},
			mainPageApplyNow() {
				onCleanNodes(['auth-link']);
				if (sessionStorage.getItem('max_done_guide_step') === 'mainPageApplyNow') {
					onPulse('overview-banner');
					onPulse('apply-now-link');
				}
			},
			mainPageApplyNowDisabled() {
				onCleanNodes(['auth-link', 'overview-banner', 'apply-now-link']);
			},
			admissionPageAllowCert() {
				onPulse('allow-cert');
				setAllRightMessageType('admission');
			},
			admissionPageAllowCertPDF() {
				onPulse('allow-cert');
				setAllRightMessageType('pdf-shared');
			},
			admissionPageAllowed() {
				setAllRightPopupIsOpen(true);
			},
			admissionPageAllowedPDF() {
				onCleanNodes(['allow-cert']);
				setAllRightMessageType('pdf-shared');
			},
			admissionPageAllowedShared() {
				setAllRightPopupIsOpen(true);
			},
			admissionPageRecheckPDF() {
				onPulse('get-pdf');
				onTooltip('get-pdf', t('tooltips.get-pdf-tooltip'), 'bottom', 'center');
			},
			admissionPageApplyCert() {
				onCleanNodes(['allow-cert', 'get-pdf']);
				onTooltip('download-pdf', t('tooltips.pdf-file-tooltip'), 'bottom', 'center');
				onPulse('apply-cert');
			},
			admissionPageCourseConfirmed() {
				onCleanNodes(['get-pdf', 'download-pdf']);
				setAllRightMessageType('');
				onTooltip('documents-link', t('tooltips.to-documents-page-tooltip'), 'bottom', 'center');
			},
		}),
		[setAllRightMessageType, t],
	);

	const admissionValidationRules = useMemo(
		() => ({
			admissionPageAllowCert:
				pathname === PATHS.admission &&
				!allRightMessageType &&
				user &&
				admissionState &&
				!admissionState.isSchoolCertificateReady &&
				!admissionState.isSchoolCertificateRequested &&
				!admissionState.isUniversityCourseConfirmed,
			admissionPageAllowCertPDF:
				pathname === PATHS.admission &&
				!allRightMessageType &&
				user &&
				admissionState?.isSchoolCertificateReady &&
				!admissionState?.isSchoolCertificateRequested &&
				!admissionState?.isUniversityCourseConfirmed,
			admissionPageAllowed:
				pathname === PATHS.admission && allRightMessageType === 'admission' && user && admissionState?.isSchoolCertificateRequested,
			admissionPageAllowedPDF:
				pathname === PATHS.admission &&
				!allRightMessageType &&
				user &&
				!admissionState?.isSchoolCertificateReady &&
				admissionState?.isSchoolCertificateRequested &&
				!admissionState?.isUniversityCourseConfirmed,
			admissionPageAllowedShared:
				pathname === PATHS.admission &&
				allRightMessageType === 'pdf-shared' &&
				user &&
				admissionState?.isSchoolCertificateReady &&
				admissionState?.isSchoolCertificateRequested &&
				!admissionState?.isUniversityCourseConfirmed,
			admissionPageRecheckPDF:
				pathname === PATHS.admission &&
				!allRightMessageType &&
				user &&
				admissionState?.isSchoolCertificateReady &&
				admissionState?.isSchoolCertificateRequested &&
				!admissionState?.isUniversityCourseConfirmed,
			admissionPageApplyCert:
				pathname === PATHS.admission &&
				allRightMessageType === 'pdf-ready-to-apply' &&
				user &&
				admissionState?.isSchoolCertificateReady &&
				admissionState?.isSchoolCertificateRequested &&
				!admissionState?.isUniversityCourseConfirmed,
			admissionPageCourseConfirmed:
				pathname === PATHS.admission &&
				user &&
				admissionState?.isSchoolCertificateReady &&
				admissionState?.isSchoolCertificateRequested &&
				admissionState?.isUniversityCourseConfirmed,
		}),
		[user, admissionState, allRightMessageType, pathname],
	);

	const validationRules = useMemo(
		() => ({
			beforeAuth: pathname === PATHS.home && !authProfile,
			afterAuth: allRightMessageType === 'logined' && sessionStorage.getItem('max_done_guide_step') === 'beforeAuth',
			userNotOnboarded: !qrIsOpen && !user && userStatusCode === '410',
			userOnboardingPopup: qrIsOpen && !user,
			userWaitingOnboarding: !qrIsOpen && !user && userStatusCode === '403',
			userOnboarded: allRightMessageType === 'onboarded' && user,
			userPage: pathname === PATHS.user && user,
			documentsPage: pathname === PATHS.documents && user && documents?.length && !sessionStorage.getItem('document_downloaded'),
			mainPageApplyNow: pathname === PATHS.home && user && !admissionState?.isSchoolCertificateRequested,
			mainPageApplyNowDisabled: pathname === PATHS.home && user && admissionState?.isSchoolCertificateRequested,
			...admissionValidationRules,
		}),
		[
			pathname,
			authProfile,
			allRightMessageType,
			qrIsOpen,
			user,
			userStatusCode,
			documents?.length,
			admissionState?.isSchoolCertificateRequested,
			admissionValidationRules,
		],
	);

	const updateStep = useCallback(
		(forceUpdate = false) => {
			const stepValidator = () => {
				for (let key in validationRules) {
					if (validationRules[key]) {
						return key;
					}
				}
			};

			const setNewStep = () => {
				const newStep = stepValidator();

				if (newStep && (currStepRef.current !== newStep || forceUpdate)) {
					const allSteps = Object.keys(stepRunners);
					const newStepIndex = allSteps.indexOf(newStep);
					const maxDoneStepIndex = allSteps.indexOf(sessionStorage.getItem('max_done_guide_step'));

					if (maxDoneStepIndex < newStepIndex) {
						sessionStorage.setItem('max_done_guide_step', newStep.toString());
					}

					currStepRef.current = newStep;
					console.info('-- Run step:', currStepRef.current);
					stepRunners[currStepRef.current]();
				}
			};

			return setNewStep();
		},
		[stepRunners, validationRules],
	);

	useEffect(() => {
		const handleUpdate = () => {
			clearTimeout(debounceRef.current);
			debounceRef.current = setTimeout(updateStep, 100);
		};
		handleUpdate();
	}, [
		authProfile,
		qrIsOpen,
		user,
		admissionState,
		documents?.length,
		allRightMessageType,
		pathname,
		isGuideStepUpdate,
		userStatusCode,
		updateStep,
	]);

	useEffect(() => {
		clearTimeout(debounceRef.current);
		updateStep(true);
	}, [i18n.language, updateStep]);

	const allRightHandler = useCallback(() => {
		setAllRightPopupIsOpen(false);
		setAllRightMessageType('');
	}, [setAllRightMessageType]);

	return <AllRightMessagePopup messageType={allRightMessageType} isOpen={allRightPopupIsOpen} handler={allRightHandler} />;
});
