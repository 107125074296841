import { createTheme } from '@mui/material';

export const THEME = createTheme({
	palette: {
		primary: {
			light: '#565c65',
			main: '#32414b',
			dark: '#32414b',
			text: '#fff',
		},
		secondary: {
			light: '#a51e37',
			main: '#a51e37',
			dark: '#7a1629',
			text: '#565c65',
		},
		gold: {
			main: '#997000',
		},
		gray: {
			background: '#f2f2f2',
			border: '#dddddd',
		},
	},
	typography: {
		fontFamily: 'Open Sans',
		fontSize: '16px',

		h1: {
			fontSize: '29px',
			fontWeight: 'bold',
			color: '#32414b',
		},
		h2: {
			fontSize: '24px',
			fontWeight: 'bold',
			color: '#32414b',
		},
		h3: {
			fontSize: '19px',
			textTransform: 'uppercase',
			fontWeight: 'bold',
			color: '#fff',
		},
		h4: {
			fontSize: '16px',
			fontWeight: 'bold',
			color: '#32414b',
		},
		h5: {
			fontSize: '27px',
		},
	},
	variables: {
		maxWidth: '1200px',
	},
});
