import React, { useCallback, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { useStores } from 'common-frontend/components/store-provider';
import { REMOVE_RELATIONSHIP } from 'common-frontend/services/api-calls';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { AcceptPopup } from 'common-frontend/components/accept-popup';
import { VersionsMemo } from '../versions-view';
import { useNavigate } from 'react-router-dom';

export const Footer = observer(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { UserStore, AuthStore, AdmissionStore, DocumentsStore } = useStores();
	const { user, userStatusCode } = UserStore;
	const { authProfile } = AuthStore;

	const [localLoader, setLocalLoader] = useState(false);
	const [removeUserPopup, setRemoveUserPopup] = useState(false);

	const removeRelationship = useCallback(() => {
		setLocalLoader(true);
		setRemoveUserPopup(false);

		REMOVE_RELATIONSHIP().finally(() => {
			AdmissionStore.reset();
			DocumentsStore.reset();
			setLocalLoader(false);
			navigate('/');
		});
	}, [AdmissionStore, DocumentsStore, navigate]);

	return (
		<footer className="footer-user">
			<Typography>{t('footer-user.title')}</Typography>
			{(user || (authProfile && userStatusCode === '403')) && (
				<>
					<Button id="remove-user-button" disabled={localLoader} variant="contained" onClick={() => setRemoveUserPopup(true)}>
						{t('footer-user.remove-user.button')}
					</Button>
					<AcceptPopup
						id="remove-user"
						title={t('footer-user.remove-user.title')}
						text={t('footer-user.remove-user.dialog')}
						isOpen={removeUserPopup}
						isDanger={true}
						isWarning={true}
						confirmText={t('footer-user.remove-user.confirm')}
						onClose={() => {
							setRemoveUserPopup(false);
						}}
						action={removeRelationship}
					/>
				</>
			)}
			<VersionsMemo />
		</footer>
	);
});
